import PeopleBlock from "components/agency/PeopleBlock";
import ServicesBlock from "components/agency/ServicesBlock";
import SimpleCarousel from "components/agency/SimpleCarousel";
import BigPageLink from "components/BigPageLink";
import TextBlock from "components/blocks/TextBlock";
import TextWithImageBlock from "components/blocks/TextWithImageBlock";
import PrinciplesItemRenderer from "components/carousel-item-renderers/PrinciplesItemRenderer";
import Footer from "components/Footer";
import ExpectationsBlock from "components/internships/ExpectationsBlock";
import FormBlock from "components/internships/FormBlock";
import Hero from "components/internships/Hero";
import { normal } from "components/typography/sizes";
import { graphql } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import React from "react";
import { Box } from "rebass";
import { withTheme } from "styled-components";
import BaseTemplate from "templates/base";

const renderBlock = (idx, block, ready, onHeroImageLoaded, theme) => {
  const apiKey = block && block.model ? block.model.apiKey : null;
  let Cmp = null;
  let extras = {};

  switch (apiKey) {
    case "internships_hero":
      extras.onImageLoaded = onHeroImageLoaded;
      extras.ready = ready;
      Cmp = Hero;
      break;
    case "form_block":
      Cmp = FormBlock;
      extras = {
        contentProps: {
          first: true,
          fontSize: normal,
          fontFamily: "Larish",
          fontWeight: "semibold",
          size: "small",
        },
      };
      break;
    case "expectations_block":
      Cmp = ExpectationsBlock;
      extras = {
        contentProps: {
          first: true,
          size: "small",
        },
      };
      break;
    case "text_block":
      Cmp = TextBlock;
      extras = {
        sectionProps: { pb: [40, 60, 80, 100, 120] },
        contentProps: {
          first: true,
          fontFamily: "Larish",
          fontWeight: "semibold",
          size: "small",
          fontSize: [24, 32, 40, 48, 54],
        },
      };
      break;
    case "text_image_block":
      Cmp = TextWithImageBlock;
      extras = {
        imageProps: {
          critical: true,
        },
        sectionProps: {
          pb: idx === 1 ? [40, 60, 80] : theme.gap.big,
        },

        contentProps: {
          vcenter: idx === 2 ? 1 : 0,
          css: `
          & h2 {
            font-size: 50px !important;
            font-family: 'Druk' !important;
            line-height: 1 !important;
            strong {
              color: ${theme.fgColor};
            }
            @media screen and (min-width:${
              parseInt(theme.breakpoints[0]) + 1
            }px){
              font-size: 58px !important;
            }
            @media screen and (min-width:${
              parseInt(theme.breakpoints[3]) + 1
            }px){
              font-size: 70px !important;
            }
          }
        `,
        },
      };
      break;
    case "principles_carousel":
      Cmp = SimpleCarousel;
      extras = {
        sideLabel: "Principles",
        nextArrowOffset: -3,
        showNumbers: false,
        nextArrowColor: theme.fgColor,
        big: true,
        ItemRenderer: PrinciplesItemRenderer,
      };
      break;
    case "services_block":
      Cmp = ServicesBlock;
      break;
    case "people_block":
      Cmp = PeopleBlock;
      break;
    case "big_page_link":
      Cmp = BigPageLink;
      extras = { big: true };
      break;
    default:
      break;
  }
  if (idx === 0) {
    extras.sectionProps = { ...extras.sectionProps, autoShow: true };
  }
  if (Cmp) {
    return <Cmp theme={theme} key={block.id} {...block} {...extras} />;
  }

  return null;
};

class InternshipsTemplate extends BaseTemplate {
  get usePageReadyTimeout() {
    return false;
  }

  componentDidMount() {
    this.pageReadyTimeout = setTimeout(this.onPageReady, 15000);
  }

  componentWillUnmount() {
    clearTimeout(this.pageReadyTimeout);
  }

  onHeroImageLoaded = () => {
    this.setPageReadyTimeout(1500);
  };

  render() {
    const { ready } = this.state;
    const { data, theme } = this.props;
    const { page } = data;
    const { seoMetaTags, body } = page;
    return (
      <React.Fragment>
        <HelmetDatoCms seo={seoMetaTags}>
          <link rel="preload" as="image" href="/loader-pink.gif" />
        </HelmetDatoCms>
        <Box position="relative" display="block" pt={[150, 150, 80]}>
          {body &&
            body.map((block, idx) =>
              renderBlock(idx, block, ready, this.onHeroImageLoaded, theme)
            )}
        </Box>
        <Footer theme={theme} globals={this.props.pageContext.globals} />
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query {
    page: datoCmsInternshipsPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heading
      body {
        ... on DatoCmsInternshipsHero {
          model {
            apiKey
          }
          id
          heading
          icon {
            url
            alt
            fluid(maxWidth: 600, imgixParams: { auto: "compress,format" }) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          image {
            url
            alt
            fluid(
              maxWidth: 1440
              imgixParams: { auto: "compress,format", fm: "jpg", q: 90 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        ... on DatoCmsFormBlock {
          id
          model {
            apiKey
          }
          active
          asideHeading
          formIntroNode {
            childMarkdownRemark {
              html
            }
          }
          jobOptions
        }
        ... on DatoCmsExpectationsBlock {
          id
          model {
            apiKey
          }
          asideHeading
          column1Node {
            childMarkdownRemark {
              html
            }
          }
          column2Node {
            childMarkdownRemark {
              html
            }
          }
          column3Node {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsTextBlock {
          id
          model {
            apiKey
          }
          showAside
          asidePrefix
          asideHeading
          contentNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsTextImageBlock {
          id
          model {
            apiKey
          }
          layout
          fullBleedImage
          image {
            url
            alt
            fluid(
              maxWidth: 1366
              imgixParams: { auto: "compress,format", fm: "jpg", q: 90 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
          contentNode {
            childMarkdownRemark {
              html
            }
          }
        }
        ... on DatoCmsPrinciplesCarousel {
          id
          model {
            apiKey
          }
          items {
            topPhrase
            bottomPhrase
            descriptionParagraph
          }
        }
        ... on DatoCmsServicesBlock {
          id
          model {
            apiKey
          }
          topParagraphNode {
            childMarkdownRemark {
              html
            }
          }
          service {
            name
            subservice
          }
        }
        ... on DatoCmsPeopleBlock {
          id
          model {
            apiKey
          }
          heading
          person {
            name
            headshot {
              url
              alt
              fluid(
                maxWidth: 634
                imgixParams: { auto: "compress,format", fm: "jpg", q: 90 }
              ) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            }
            jobTitle
            bioNode {
              childMarkdownRemark {
                html
              }
            }
            linkedinUrl
          }
        }
        ... on DatoCmsBigPageLink {
          id
          model {
            apiKey
          }
          paragraphNode {
            childMarkdownRemark {
              html
            }
          }
          linkLabel
          link
        }
      }
    }
  }
`;

export default withTheme(InternshipsTemplate);
